<template>
  <b-card
    align="center"
    class="mx-auto"
  >
    <div class="mb-3">
      <h1>Credores</h1>
    </div>
    <div class="align-items-center">
      <b-row class="avg-sessions pt-50">
        <b-col>
          <div
            v-for="(employee) in employeeData"
            :key="employee.nome"
            class="employee-task d-flex justify-content-between align-items-center mb-3"
          >
            <b-col class="col-avatar">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    class="mb-1"
                    :text="getInitials(employee.nome)"
                    size="40"
                  />
                  <h7 class="mt-1 ml-2">
                    {{ employee.nome }}
                  </h7>
                </b-media-aside>
              </b-media>
            </b-col>
            <b-col class="col-meta">
              <h5 class="mb-0">
                Meta: {{ `R$ ${valorBr(parseFloat(employee.meta))}` }}
              </h5>
            </b-col>
            <b-col class="col-progress">
              <div>
                <div class="progress-container">
                  <b-progress
                    :value="employee.porcentagem_meta"
                    max="100"
                    height="20px"
                    align-self="center"
                  />
                  <div class="progress-text">
                    {{ formatPorcentagemMeta(employee.porcentagem_meta) }}%
                  </div>
                </div>
              </div>
            </b-col>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BProgress, BAvatar, BMediaAside,
//    BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BProgress,
    BAvatar,
    BMediaAside,
    // BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      employeeData: [],
      keyProgress: [1],
      avgData: {},
    }
  },
  created() {
    this.getMetaCredores()
  },
  methods: {
    formatPorcentagemMeta(porcentagem) {
      const num = parseFloat(porcentagem)
      return num.toFixed(2)
    },
    getMetaCredores() {
      axios.get('api/v1/dashboard/metas_credores', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
        .then(res => {
          res.data.dados.forEach(item => {
            this.employeeData.push(item)
          })

          // ordena o array employeeData com base no valor da porcentagem de meta
          this.employeeData.sort((a, b) => b.porcentagem_meta - a.porcentagem_meta)
        })
        .catch()
    },
    kFormatter,
  },
}
</script>
    <style scoped>
      .progress-container {
        position: relative;
        height: 6px;
      }
      .progress-text {
        position: absolute;
        right: 0;
        top: -1rem;
        font-size: 0.8rem;
        color: #fff;
        background-color: #007aff;
        padding: 0.2rem 0.5rem;
        border-radius: 0.2rem;
      }
      .col-avatar {
  width: 200px;
}

.col-meta {
  width: 120px;
}

.col-progress {
  width: 200px;
}
    </style>
